import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import FAQAccordion from './FAQAccordion';
import Header from './Header';
import { ThemeContext } from './ThemeContext';

const LandingPage = () => {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div className={`min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col transition-colors duration-500 ${darkMode ? 'dark' : ''}`}>
      {/* Header with Dark Mode Toggle */}
      <Header />

      {/* Main Content */}
      <main className="flex-grow container mx-auto p-6">
        {/* Launchpad Section */}
        <section className="my-16">
          <h1 className="text-6xl md:text-7xl font-bold text-center mb-8 text-gray-800 dark:text-gray-100">
            The Future of NFTs is Physical
          </h1>
          <p className="text-center text-2xl md:text-3xl text-gray-700 dark:text-gray-300">
            Instantly launch your physical NFT with the push of a button.
          </p>
          {/* Banner Images */}
        <section className="mb-16">
          <img 
            src={darkMode ? "https://assets.shirt.haus/banner-light.png" : "https://assets.shirt.haus/banner-dark.png"}
            alt="Banner 1" 
            className="w-full h-auto "
          />
        </section>
          <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-8">
            {/* Free Tier */}
            <div className="w-full md:w-1/3 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-colors duration-500 flex flex-col">
              <div className="flex-grow">
                <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Free Tier</h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  Launch your collection for free. Enjoy hassle-free setup.
                </p>
                <ul className="mb-6">
                  <li className="flex items-center mb-2 text-gray-600 dark:text-gray-300">
                    <span className="text-green-500 mr-2">✔️</span>Free Collection Launch
                  </li>
                  <li className="flex items-center text-gray-600 dark:text-gray-300">
                    <span className="text-green-500 mr-2">✔️</span>Basic Support
                  </li>
                </ul>
              </div>
              <Link
                to="/launch-collection"
                className="w-full bg-green-500 text-white dark:bg-green-700 dark:text-white px-4 py-3 rounded-lg text-center hover:bg-green-600 dark:hover:bg-green-800 transition text-xl font-semibold"
              >
                Get Started
              </Link>
            </div>

            {/* Basic Tier */}
            <div className="w-full md:w-1/3 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-colors duration-500 flex flex-col">
              <div className="flex-grow">
                <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Basic Tier</h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  Launch up to 3 collections with a 50/50 profit split.
                </p>
                <ul className="mb-6">
                  <li className="flex items-center mb-2 text-gray-600 dark:text-gray-300">
                    <span className="text-green-500 mr-2">✔️</span>1 Collection included
                  </li>
                  <li className="flex items-center mb-2 text-gray-600 dark:text-gray-300">
                    <span className="text-green-500 mr-2">✔️</span>50/50 Profit Split
                  </li>
                  <li className="flex items-center text-gray-600 dark:text-gray-300">
                    <span className="text-green-500 mr-2">✔️</span>Priority Support
                  </li>
                </ul>
              </div>
              {process.env.REACT_APP_FREE_TIER_ONLY === 'true' ? (
                <button
                  className="w-full bg-gray-400 text-white px-4 py-3 rounded-lg text-center cursor-not-allowed text-xl font-semibold"
                  disabled
                >
                  Coming Soon
                </button>
              ) : (
                <Link
                  to="/launch-collection"
                  className="w-full bg-purple-600 text-white px-4 py-3 rounded-lg text-center hover:bg-purple-700 transition text-xl font-semibold"
                >
                  Choose Basic ($29.99/month)
                </Link>
              )}
            </div>

            {/* Ultimate Tier */}
            <div className="w-full md:w-1/3 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-colors duration-500 flex flex-col">
              <div className="flex-grow">
                <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Ultimate Tier</h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  Launch unlimited collections with minimal commissions.
                </p>
                <ul className="mb-6">
                  <li className="flex items-center mb-2 text-gray-600 dark:text-gray-300">
                    <span className="text-green-500 mr-2">✔️</span>Unlimited Collection Launches
                  </li>
                  <li className="flex items-center mb-2 text-gray-600 dark:text-gray-300">
                    <span className="text-green-500 mr-2">✔️</span>90/10 Profit Split
                  </li>
                  <li className="flex items-center mb-2 text-gray-600 dark:text-gray-300">
                    <span className="text-green-500 mr-2">✔️</span>Free Shirt on Signup
                  </li>
                  <li className="flex items-center mb-2 text-gray-600 dark:text-gray-300">
                    <span className="text-green-500 mr-2">✔️</span>Priority Support
                  </li>
                </ul>
              </div>
              {process.env.REACT_APP_FREE_TIER_ONLY === 'true' ? (
                <button
                  className="w-full bg-gray-400 text-white px-4 py-3 rounded-lg text-center cursor-not-allowed text-xl font-semibold"
                  disabled
                >
                  Coming Soon
                </button>
              ) : (
                <Link
                  to="/launch-collection"
                  className="w-full bg-green-500 text-white px-4 py-3 rounded-lg text-center hover:bg-green-600 transition text-xl font-semibold"
                >
                  Choose Ultimate ($99.99/month)
                </Link>
              )}
            </div>
          </div>
        </section>

        {/* Updated Easy Launch Section */}
        <section className="my-16 bg-gray-50 dark:bg-gray-800 p-8 rounded-lg shadow-lg transition-colors duration-500">
          <h2 className="text-5xl md:text-6xl font-semibold mb-6 text-center text-gray-800 dark:text-gray-100">
            Launch Your Physical NFT in Seconds
          </h2>
          <p className="text-center text-3xl md:text-4xl text-gray-700 dark:text-gray-300 mb-8">
            Say goodbye to endless setup times and exorbitant costs.<br></br>Streamline the process,
            <span className="font-bold text-green-500 dark:text-green-400"> save over 69 hours</span> and <span className="font-bold text-green-500 dark:text-green-400">bring your digital creations to life.</span> 
          </p>
          <div className="flex flex-col md:flex-row justify-between space-y-8 md:space-y-0 md:space-x-12">
            {/* Advantages List */}
            <div className="md:w-1/2">
              <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Advantages</h3>
              <ul className="space-y-4 text-left text-gray-700 dark:text-gray-300">
                <li className="flex items-start">
                  <span className="text-green-500 dark:text-green-400 mr-2">✔️</span>
                  <span><strong>Time Saved:</strong> Launch in seconds instead of weeks.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 dark:text-green-400 mr-2">✔️</span>
                  <span><strong>Cost Efficient:</strong> Avoid monthly fees and setup costs.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 dark:text-green-400 mr-2">✔️</span>
                  <span><strong>Satisfaction Guaranteed:</strong> We stand behind our products and your fans will love them.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 dark:text-green-400 mr-2">✔️</span>
                  <span><strong>Seamless Integration:</strong> Connect with your fans without the hassle.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 dark:text-green-400 mr-2">✔️</span>
                  <span><strong>Solana-Powered:</strong> Bring the Solana spirit into the physical world effortlessly.</span>
                </li>
              </ul>
              {/* Illustration */}
              <div className="mt-8">
                <img
                  src={darkMode ? "https://assets.shirt.haus/launch-dark.png" : "https://assets.shirt.haus/launch-light.png"}
                  alt="Launchpad Illustration"
                  className="w-full h-auto rounded-lg shadow-lg"
                />
              </div>
              <div className="mt-8 text-center">
            <Link
              to="/launch-collection"
              className="text-xl bg-green-500 dark:bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-600 dark:hover:bg-green-700 transition text-lg font-semibold"
            >
              Bring Your NFTs to Life
            </Link>
          </div>
            </div>

            {/* Tedious Tasks Eliminated */}
            <div className="md:w-1/2">
              <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Tedious Tasks Eliminated</h3>
              <ul className="space-y-4 text-left text-gray-700 dark:text-gray-300">
                <li className="flex items-start">
                  <span className="text-red-500 dark:text-red-400 mr-2">❌</span>
                  <span><strong>No Business Registration:</strong> Skip the complex process of registering a business.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-red-500 dark:text-red-400 mr-2">❌</span>
                  <span><strong>No Shopify Setup:</strong> Save $35/month cost and save +10 hours.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-red-500 dark:text-red-400 mr-2">❌</span>
                  <span><strong>No Custom Domain & Email:</strong> Save $10/month and +4 hours of setup.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-red-500 dark:text-red-400 mr-2">❌</span>
                  <span><strong>No Supplier Hunting:</strong> Forget the +20 hours spent finding the right suppliers and logistics partners.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-red-500 dark:text-red-400 mr-2">❌</span>
                  <span><strong>No Marketing Struggles:</strong> Let us handle the marketing so you can focus on creating.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-red-500 dark:text-red-400 mr-2">❌</span>
                  <span><strong>No sample testing:</strong> Save $100+ on sample testing. Every product is tested before shipping.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-red-500 dark:text-red-400 mr-2">❌</span>
                  <span><strong>No customer support:</strong> No emails back and forth.</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* Introduction */}
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-gray-800 dark:text-gray-100">
            Mint Your Custom NFT Shirt in 3 Easy Steps
          </h2>
          <p className="text-2xl md:text-3xl text-gray-700 dark:text-gray-300">
            Transform your favorite NFTs into unique, wearable art with just a few clicks!
          </p>
        </section>

        {/* Features Sections */}
        <section className="space-y-16">
          {/* Feature 1 */}
          <div className="flex flex-col md:flex-row items-center bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg transition-colors duration-500">
            <div className="md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Connect Your Wallet</h3>
              <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-300">
                Securely connect your Web3 wallet to get started. Our platform ensures your transactions are safe and seamless.
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex flex-col md:flex-row-reverse items-center bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg transition-colors duration-500">
            <div className="md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Select Your NFT</h3>
              <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-300">
                Choose from your approved NFT collection. Customize your selection to create a unique design that reflects your style.
              </p>
            </div>
          </div>

          {/* Feature 3 - Mint Your Shirt */}
          <div className="flex flex-col md:flex-row items-center bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg transition-colors duration-500">
            <div className="md:w-1/2">
              <img
                src="/assets/shirt-example.png"
                alt="Mint Your Shirt"
                className="w-full h-auto"
              />
            </div>
            <div className="md:w-1/2 md:pl-8 mt-6 md:mt-0">
              <h3 className="text-3xl md:text-4xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Mint Your Shirt</h3>
              <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-300">
                Mint your custom shirt for only 0.35 SOL. Your personalized NFT shirt will be shipped directly to your doorstep.
              </p>
            </div>
          </div>
        </section>

        {/* New: Easy 3 Steps Section */}
        <section className="mt-16">
          <h3 className="text-4xl md:text-5xl font-semibold mb-8 text-center text-gray-800 dark:text-gray-100">How It Works</h3>
          <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-8">
            {/* Step 1 */}
            <div className="flex flex-col items-center bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-colors duration-500">
              <div className="mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-purple-600 dark:text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
              </div>
              <h4 className="text-2xl font-semibold mb-2 text-gray-800 dark:text-gray-100">Step 1: Connect Wallet</h4>
              <p className="text-center text-gray-600 dark:text-gray-300">Securely connect your Web3 wallet to begin your minting journey.</p>
            </div>

            {/* Step 2 */}
            <div className="flex flex-col items-center bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-colors duration-500">
              <div className="mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-green-500 dark:text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h4 className="text-2xl font-semibold mb-2 text-gray-800 dark:text-gray-100">Step 2: Select NFT</h4>
              <p className="text-center text-gray-600 dark:text-gray-300">Choose your favorite NFT from your collection to customize.</p>
            </div>

            {/* Step 3 */}
            <div className="flex flex-col items-center bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-colors duration-500">
              <div className="mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12H8m0 0l4-4m-4 4l4 4" />
                </svg>
              </div>
              <h4 className="text-2xl font-semibold mb-2 text-gray-800 dark:text-gray-100">Step 3: Mint Shirt</h4>
              <p className="text-center text-gray-600 dark:text-gray-300">Finalize your design and mint your unique NFT shirt effortlessly.</p>
            </div>
          </div>
        </section>

        {/* Highlights
        <section className="text-center my-16">
          <h3 className="text-4xl md:text-5xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Limited Edition</h3>
          <p className="text-2xl md:text-3xl text-gray-700 dark:text-gray-300">Join our exclusive 1000 NFT mint and own a piece of the future!</p>
          <p className="text-2xl md:text-3xl text-gray-700 dark:text-gray-300 mt-2">Price: 0.35 SOL per shirt</p>
        </section> */}

        {/* Action Buttons */}
        <section className="flex justify-center space-x-4 my-16">
          <Link
            to="/mint"
            className="bg-purple-600 dark:bg-purple-700 text-white px-8 py-4 rounded-lg shadow-lg hover:bg-purple-700 dark:hover:bg-purple-800 transition text-xl md:text-2xl"
          >
            Start Minting
          </Link>
          <Link
            to="/launch-collection"
            className="bg-green-500 dark:bg-green-600 text-white px-8 py-4 rounded-lg shadow-lg hover:bg-green-600 dark:hover:bg-green-700 transition text-xl md:text-2xl"
          >
            Launch Your Physical NFTs
          </Link>
        </section>

        {/* FAQ Section */}
        <section className="max-w-3xl mx-auto mb-8">
          <h3 className="text-4xl md:text-5xl font-semibold mb-6 text-center text-gray-800 dark:text-gray-100">Frequently Asked Questions</h3>
          <FAQAccordion />
        </section>

      </main>

      {/* Footer */}
      <footer className="bg-white dark:bg-gray-800 shadow">
        <div className="container mx-auto p-6 text-center text-gray-600 dark:text-gray-300">
          &copy; {new Date().getFullYear()} shirt.haus. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;