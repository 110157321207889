import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import Header from './Header';
import { FaPen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

const Approve = () => {
  const { publicKey } = useWallet();
  const [collections, setCollections] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingNameId, setEditingNameId] = useState(null);
  const [temporaryName, setTemporaryName] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [approvedCollectionName, setApprovedCollectionName] = useState('');
  const [approvedCollectionId, setApprovedCollectionId] = useState(''); // Added state for collection ID

  useEffect(() => {
    if (publicKey) {
      fetchCollections();
    }
  }, [publicKey]);

  const fetchCollections = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allowlist/collections/${publicKey.toString()}`);
      if (response.ok) {
        const data = await response.json();
        setCollections(data);
      } else {
        toast.error('Error fetching collections');
      }
    } catch (error) {
      console.error('Error fetching collections:', error);
      toast.error('Error fetching collections');
    }
  };

  const handleApprove = async (collectionId) => {
    setIsSubmitting(true);

    const collection = collections.find(c => c.collectionId === collectionId);
    if (!collection) {
      toast.error('Collection not found');
      setIsSubmitting(false);
      return;
    }

    const payload = {
      _id: collectionId,
      type: "collection",
      allowedMerch: ["shirt", "hoodie", "tank top", "print", "canvas", "mug"],
      name: collection.name || 'Unnamed Collection',
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allowlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const approvedName = collection.name || 'Unnamed Collection';
        toast.success(`Collection "${approvedName}" launched successfully!`);
        setApprovedCollectionName(approvedName);
        setApprovedCollectionId(collectionId); // Set the approved collection ID
        setIsSuccessModalOpen(true);
        fetchCollections(); // Refresh the list
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message || 'Something went wrong.'}`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const startEditing = (collectionId, currentName) => {
    setEditingNameId(collectionId);
    setTemporaryName(currentName || '');
  };

  const cancelEditing = () => {
    setEditingNameId(null);
    setTemporaryName('');
  };

  const saveName = async (collectionId) => {
    const trimmedName = temporaryName.trim() || 'Unnamed Collection';
    
    // Optimistically update the UI
    setCollections(
      collections.map(collection => 
        collection.collectionId === collectionId 
          ? { ...collection, name: trimmedName } 
          : collection
      )
    );
    setEditingNameId(null);
    setTemporaryName('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allowlist/collections/update-name`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          collectionId,
          newName: trimmedName,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error || 'Failed to update name.'}`);
        // Optionally, refetch collections to sync state
        fetchCollections();
      } else {
        const updatedCollection = await response.json();
        toast.success(`Collection name updated to "${updatedCollection.name}" successfully!`);
      }
    } catch (error) {
      console.error('Error updating collection name:', error);
      toast.error(`Error: ${error.message}`);
      // Optionally, refetch collections to sync state
      fetchCollections();
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
  };

  const handleTweet = () => {
    const tweetText = encodeURIComponent(
      `I just launched my physical NFT collection "${approvedCollectionName}" on @shirt_haus! Check it out: ${window.location.origin}/mint?collectionId=${approvedCollectionId}`
    );
    const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;
    window.open(twitterUrl, '_blank');
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <div className="flex-grow flex items-center justify-center bg-gray-100 dark:bg-gray-800">
        <div className="bg-white dark:bg-gray-700 p-8 rounded-lg shadow-lg max-w-2xl w-full">
          <h2 className="text-2xl font-bold mb-4 dark:text-white">Launch Your Physical NFTs</h2>
          {publicKey ? (
            <>
              {collections.length > 0 ? (
                <ul className="space-y-4">
                  {collections.map((collection) => (
                    <li key={collection.collectionId} className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-600 rounded-lg">
                      <div className="flex items-center space-x-4">
                        {collection.image && (
                          <img 
                            src={collection.image} 
                            alt={collection.name || 'Collection'} 
                            className="w-16 h-16 object-cover rounded-md"
                          />
                        )}
                        <div>
                          {editingNameId === collection.collectionId ? (
                            <input
                              type="text"
                              value={temporaryName}
                              onChange={(e) => setTemporaryName(e.target.value)}
                              onBlur={() => saveName(collection.collectionId)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  saveName(collection.collectionId);
                                } else if (e.key === 'Escape') {
                                  cancelEditing();
                                }
                              }}
                              autoFocus
                              className="w-full p-2 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring focus:ring-green-500 focus:ring-opacity-50"
                            />
                          ) : (
                            <div className="flex items-center">
                              <span className="text-gray-700 dark:text-gray-300 font-semibold">
                                {collection.name || 'Unnamed Collection'}
                              </span>
                              <button 
                                onClick={() => startEditing(collection.collectionId, collection.name)}
                                className="ml-2 text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-white focus:outline-none"
                                title="Edit Name"
                              >
                                <FaPen />
                              </button>
                            </div>
                          )}
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            ID: {collection.collectionId}
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => handleApprove(collection.collectionId)}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
                        disabled={isSubmitting}
                      >
                        Launch
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-700 dark:text-gray-300">No collections found for this wallet.</p>
              )}
            </>
          ) : (
            <p className="text-gray-700 dark:text-gray-300">Please connect your wallet to view and launch collections.</p>
          )}
        </div>
      </div>

      {/* Success Modal */}
      <Modal
        isOpen={isSuccessModalOpen}
        onRequestClose={closeModal}
        contentLabel="Collection Launched"
        className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg max-w-md mx-auto fixed top-10 left-1/2 transform -translate-x-1/2 outline-none" // Updated positioning
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-100">Success!</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          Your physical NFT collection "{approvedCollectionName}" has been launched successfully.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleTweet}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
          >
            Share on Twitter
          </button>
          <button
            onClick={closeModal}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Approve;