import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import { ThemeProvider } from './components/ThemeContext';
import Minter from './components/Minter';
import LaunchCollection from './components/LaunchCollection';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Import other components as needed

const App = () => {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/mint" element={<Minter />} />
          <Route path="/launch-collection" element={<LaunchCollection />} />
          {/* Add other routes as needed */}
        </Routes>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;