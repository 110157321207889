import React, { useState, useMemo, useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { HelioCheckout } from '@heliofi/checkout-react';
import { useWallet } from '@solana/wallet-adapter-react';
import Modal from 'react-modal';

function Checkout({ cartItems, totalCost, onBack }) {
  const { publicKey } = useWallet();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    street: '',
    streetNumber: '',
    city: '',
    zipCode: '',
    country: '',
    giftNote: '',
    noteToTeam: '',
  });

  const [orderId, setOrderId] = useState(null); // State to store order ID after submission

  const countryOptions = countryList().getData();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const handleTweet = useCallback(() => {
    const tweet = `I just minted a shirt on the @shirt_haus launchpad!`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet)}`;
    window.open(twitterUrl, '_blank');
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    // make sure the format is +1234567890 and not 1234567890
    if (value.startsWith('+')) {
      setFormData({ ...formData, phone: value });
    } else {
      setFormData({ ...formData, phone: `+${value}` });
    }
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, country: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress: publicKey.toString(),
          items: cartItems.map(item => ({
            ...item,
            tokenId: item.tokenId // Ensure tokenId is included
          })),
          contactInfo: {
            name: formData.name,
            email: formData.email,
            phoneNumber: formData.phone,
          },
          shippingAddress: {
            street: formData.street,
            streetNumber: formData.streetNumber,
            city: formData.city,
            zipCode: formData.zipCode,
            country: formData.country,
          },
          giftNote: formData.giftNote,
          noteToTeam: formData.noteToTeam,
        }),
      });
      const data = await response.json();
      setOrderId(data._id);
      console.log(data);
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };

  const helioConfig = useMemo(() => ({
    paylinkId: process.env.REACT_APP_HELIO_PAYLINK_ID,
    theme: { "themeMode": "dark" },
    primaryColor: "#9945ff",
    neutralColor: "#E1E6EC",
    network: process.env.REACT_APP_HELIO_NETWORK,
    amount: process.env.REACT_APP_HELIO_NETWORK === 'test' ? '0.35' : totalCost.toFixed(2),
    display: "inline",
    additionalJSON:{
      orderId: orderId,
      cartItems: cartItems,
      totalCost: totalCost,
      publicKey: publicKey.toString(),
    },
    onSuccess: (event) => {
      console.log('Payment Successful:', event);
      setIsModalOpen(true);
    },
    onError: (event) => console.log('Payment Error:', event),
    onPending: (event) => console.log('Payment Pending:', event),
    onCancel: () => console.log("Cancelled payment"),
    onStartPayment: () => console.log("Starting payment"),
  }), [totalCost, orderId, cartItems, publicKey]);

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">Checkout</h2>
      
      <div className="mb-6">
        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">Order Summary</h3>
        {cartItems.map((item, index) => (
          <div key={index} className="flex items-center justify-between mb-4 border-b pb-4">
            <div className="flex items-center space-x-4">
              <img 
                src={item.images.front || item.images.back} 
                alt={`${item.productType} mockup`} 
                className="w-20 h-20 object-cover rounded"
              />
              <div>
                <p className="text-gray-800 dark:text-gray-200">{item.productType} - {item.size}</p>
                <p className="text-gray-600 dark:text-gray-400">{item.color}</p>
                <p className="text-gray-600 dark:text-gray-400">Quantity: {item.quantity}</p>
              </div>
            </div>
            <span className="text-gray-800 dark:text-gray-200 font-semibold">
              ${((item.price || 0) * item.quantity).toFixed(2)}
            </span>
          </div>
        ))}
        <div className="text-xl font-bold text-gray-800 dark:text-gray-100 mt-4">
          Total: ${totalCost.toFixed(2)}
        </div>
      </div>

      {!orderId ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Form Fields */}
          <div>
            <label htmlFor="name" className="block text-gray-700 dark:text-gray-300 mb-2">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 dark:text-gray-300 mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-gray-700 dark:text-gray-300 mb-2">Phone</label>
            <PhoneInput
              country={'us'}
              value={formData.phone}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'phone',
                required: true,
                className: 'w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500'
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="street" className="block text-gray-700 dark:text-gray-300 mb-2">Street</label>
              <input
                type="text"
                id="street"
                name="street"
                value={formData.street}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label htmlFor="streetNumber" className="block text-gray-700 dark:text-gray-300 mb-2">Street Number</label>
              <input
                type="text"
                id="streetNumber"
                name="streetNumber"
                value={formData.streetNumber}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
          </div>
          {/* Added City Field */}
          <div>
            <label htmlFor="city" className="block text-gray-700 dark:text-gray-300 mb-2">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="zipCode" className="block text-gray-700 dark:text-gray-300 mb-2">ZIP Code</label>
              <input
                type="text"
                id="zipCode"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label htmlFor="country" className="block text-gray-700 dark:text-gray-300 mb-2">Country</label>
              <Select
                options={countryOptions}
                value={countryOptions.find(option => option.value === formData.country)}
                onChange={handleCountryChange}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
          </div>
          <div>
            <label htmlFor="giftNote" className="block text-gray-700 dark:text-gray-300 mb-2">Gift Note (optional)</label>
            <textarea
              id="giftNote"
              name="giftNote"
              value={formData.giftNote}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            ></textarea>
          </div>
          <div>
            <label htmlFor="noteToTeam" className="block text-gray-700 dark:text-gray-300 mb-2">Note to Shirt Haus Team (optional)</label>
            <textarea
              id="noteToTeam"
              name="noteToTeam"
              value={formData.noteToTeam}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            ></textarea>
          </div>
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={onBack}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition"
            >
              Back to Cart
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 transition"
            >
              Proceed to Payment
            </button>
          </div>
        </form>
      ) : (
        <div className="mt-6">
          <HelioCheckout config={helioConfig} />
        </div>
      )}
      
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Purchase Successful"
        className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg max-w-md mx-auto fixed top-10 left-1/2 transform -translate-x-1/2 outline-none"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-100">You just minted a shirt!</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
        <strong className="text-lg font-bold text-purple-600 dark:text-purple-400">
          Congratulations! 🎉 Your physical NFT is now in your wallet, and your shirt is on its way!
        </strong>
        <br />
        <span className="text-green-500 dark:text-green-300">
          We've sent a confirmation email to <em className="underline">{formData.email}</em>.
        </span>
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleTweet}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
          >
            Share on Twitter
          </button>
          <button
            onClick={closeModal}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Checkout;