import React, { useContext } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Link } from 'react-router-dom';
import { FaHome, FaSun, FaMoon } from 'react-icons/fa';
import { ThemeContext } from './ThemeContext';

function Header({ showBackButton, children }) {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);

  return (
    <header className="bg-white dark:bg-gray-800 shadow-md transition-colors duration-500">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        {/* Left Side - Logo */}
        <div className="flex items-center">
          <Link to="/" className="text-2xl font-bold flex items-center text-gray-800 dark:text-gray-100 transition-colors duration-300">
            shirt.haus
          </Link>
        </div>

        {/* Right Side - Navigation Links and Buttons */}
        <div className="flex items-center space-x-4">
          {/* Home Link */}
          <Link to="/" className="text-2xl font-bold flex items-center text-gray-800 dark:text-gray-100 transition-colors duration-300">
            <FaHome className="mr-2" />
          </Link>

          {/* Dark/Light Mode Toggle */}
          <button
            onClick={toggleDarkMode}
            className="flex items-center bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-4 py-2 rounded-lg shadow-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300 text-lg"
          >
            {darkMode ? <FaSun className="mr-2" /> : <FaMoon className="mr-2" />}
            {darkMode ? 'Light Mode' : 'Dark Mode'}
          </button>

          {/* Mint Button */}
          <Link
            to="/mint"
            className="bg-purple-600 dark:bg-purple-700 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-purple-700 dark:hover:bg-purple-800 transition-colors duration-300 text-lg"
          >
            Mint
          </Link>

          {/* Launch Your Physical NFTs Button */}
          <Link
            to="/launch-collection"
            className="bg-green-500 dark:bg-green-600 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-green-600 dark:hover:bg-green-700 transition-colors duration-300 text-lg"
          >
            Launch Your Physical NFTs
          </Link>

          {/* Wallet Connect Button */}
          <WalletMultiButton className="bg-purple-600 dark:bg-purple-700 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-purple-700 dark:hover:bg-purple-800 transition-colors duration-300 text-lg" />

          {children}
        </div>
      </div>
    </header>
  );
}

export default Header;