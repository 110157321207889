import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Checkout from './Checkout';

function Cart({ cartItems: initialCartItems, removeFromCart, onClose, fetchCartItems }) {
  const [cartItems, setCartItems] = useState(initialCartItems);
  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    if (fetchCartItems) {
      fetchCartItems().then(() => {
        // Fetch cart items is already setting cartItems state
      });
    }
  }, [fetchCartItems]);

  useEffect(() => {
    setCartItems(initialCartItems);
  }, [initialCartItems]);

  const totalCost = cartItems?.reduce((total, item) => total + (item.price || 0) * item.quantity, 0) || 0;

  return (
    <div className="container mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      {!showCheckout ? (
        <>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-800 dark:text-gray-100">Mint Cart</h1>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          {cartItems.length === 0 ? (
            <p className="text-gray-600 dark:text-gray-300">Your cart is empty</p>
          ) : (
            <div className="space-y-4">
              {cartItems.map((item, index) => (
                <div key={index} className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
                  <div className="flex items-center space-x-4">
                    <img src={item.images.front || item.images.back} alt="Product" className="w-16 h-16 object-cover rounded" />
                    <div>
                      <p className="text-gray-800 dark:text-gray-200">{item.productType} - {item.size}</p>
                      <p className="text-gray-600 dark:text-gray-400">{item.color}</p>
                      <p className="text-gray-600 dark:text-gray-400">Quantity: {item.quantity}</p>
                      <p className="text-gray-600 dark:text-gray-400">Token ID: {item.tokenId || 'N/A'}</p>
                      <p className="text-gray-600 dark:text-gray-400">${((item.price || 0) * item.quantity).toFixed(2)}</p>
                    </div>
                  </div>
                  <button onClick={() => removeFromCart(index)} className="text-red-500 hover:text-red-700">
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="mt-6">
            <p className="text-xl font-bold text-gray-800 dark:text-gray-100 mb-4">
              Total: ${totalCost.toFixed(2)}
            </p>
            <button 
              className="w-full bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition"
              onClick={() => setShowCheckout(true)}
            >
              Proceed to Checkout
            </button>
          </div>
        </>
      ) : (
        <Checkout cartItems={cartItems} totalCost={totalCost} onBack={() => setShowCheckout(false)} />
      )}
    </div>
  );
}

export default Cart;